import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';

import PasswordInput from '../../../../components/forms/password.input';
import TextInput from '../../../../components/forms/text.input';
import Text from '../../../../components/style/text.component';

import { updateEmail } from '../../../../services/auth.service';
import useLocale from '../../../../utils/locale/locale.hook';
import { validateEmail } from '../../../../utils/regex.util';
import { InlineForm } from './inline-form.component';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import { useNotification } from '../../../../utils/useNotification';

type EmailGestionProps = {
  me?: any;
  onChange?: (email) => void;
};

const EmailGestion = (props: EmailGestionProps) => {
  const locale = useLocale();
  const localeStore = useSelector((state: any) => state.localeStore);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [errorMsg, setErrorMsg] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { triggerNotification } = useNotification();

  const submit = async () => {
    setErrorMsg(null);
    const res = await updateEmail(email, password, localeStore.language);
    if (res?.error) {
      if (res?.error === 'global.error.unauthorized') {
        setErrorMsg('wrong_password_entered');
      } else {
        setErrorMsg(res?.error);
      }
      return;
    }
    props?.onChange(email);
    triggerNotification({
      title: locale('settings.modification_success'),
    });
    setEmail('');
    setPassword('');
  };

  return (
    <Container>
      <Header>
        <SectionTitle>{locale('account.account_gestion.email_title')}</SectionTitle>
        <Subtitle>{`(${locale('signup.credentials.current_email')} ${props.me.email})`}</Subtitle>
      </Header>
      <InlineForm style={!isDesktop ? { display: 'flex', flexDirection: 'column', gap: 12 } : null}>
        <TextInput
          value={email}
          onChange={(text) => setEmail(text)}
          placeholder={locale('signup.credentials.email')}
          inputBorderRadius={8}
          style={{
            gridColumn: 1,
          }}
        />
        <PasswordInput
          value={password}
          onChange={(text) => setPassword(text)}
          placeholder={locale('signup.credentials.confirm_password')}
          style={{
            gridColumn: 2,
          }}
          borderRadius={8}
        />
        {errorMsg && <Error>{locale(errorMsg)}</Error>}
      </InlineForm>
      <PrimaryButton
        size="large"
        isActive
        disabled={!password || password.length <= 1 || !validateEmail(email)}
        onClick={() => submit()}
        style={{ marginTop: 12, height: 36 }}
      >
        {locale('account.account_gestion.submit')}
      </PrimaryButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
`;

const SectionTitle = styled(Text)`
  font-weight: 700;
  font-size: 14px;
`;

const Subtitle = styled(Text)`
  font-size: 14px;
  font-weight: 400;
`;

const Error = styled.div`
  grid-column: 1;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: #ff5050;
  margin: 15px 0 8px;
  padding: 12px 14px;
  font-size: 12px;
`;

export default EmailGestion;
