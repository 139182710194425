import styled from 'styled-components';
import { UserModalTemplate } from '../pages/user-profile/components/user-modal.component';
import colors from '../themes/colors-v2.theme';
import { useLogin } from '../utils/auth.utils';
import useLocale from '../utils/locale/locale.hook';
import { PrimaryButton } from './forms/primaryButton.component';
import Text from './style/text.component';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import ModalSlice from '../store/slices/modal.slice';
import { useEffect } from 'react';
import { environment } from "../environments";
import { useState } from 'react';
import { generateBillingParams } from '../services/subscription.service';
import { getCurrentLanguage } from '../utils/locale/hreflang.utils';
import { getPrice } from '../services/subscription.service';
import RadioBox from './forms/radiobox.input';
import { Flex } from './style/flex.component';


type Props = {
  isOpen: boolean;
  onClose: () => void;
  style?: any;
};

export function BoostModal({ isOpen, onClose }: Props) {
  const { me } = useLogin();
  const locale = useLocale();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [priceValue, setPriceValue] = useState('');
  const [currency, setCurrency] = useState('');
  const [price, setPrice] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<
    "creditCard" | "amex"
  >("creditCard");
  const [cardType, setCardType] = useState<"CB" | "AMEX">("CB");
  const [payboxUrl, setPayboxUrl] = useState('');

  const getPriceHandler = async () => {
    const response = await getPrice('BOOST');
    setCurrency(response.currency);
    setPriceValue(response.value);
  };

  useEffect(() => {
    getPriceHandler();
    getPayboxUrlHandler()
  }, [])

  useEffect(() => {
    if (currency && priceValue) {
      setPrice(`${priceValue} ${currency}`);
    }
  }, [currency, priceValue]);

  useEffect(() => {
    if (isOpen) {
      dispatch(ModalSlice.actions.setBoostModalVisible(isOpen));
    }
    // }
  }, [isOpen]);

  const handleClose = () => {
    console.log("Boost closed")
    window.gtag('event', 'leave_boost_subscription', {
      event_category: 'Engagement',
      event_label: 'Boost Page',
    });
    dispatch(ModalSlice.actions.setBoostModalVisible(false));
    onClose();
  };

  const [paymentParams, setPaymentParams] = useState<
    Array<{ name: string; value: string }>
  >([]);

  const getPaymentParamsHandler = async (newBillingForm) => {
    await generateBillingParams(newBillingForm).then((res) => {
      if (res?.success) {
        setPaymentParams(res.params);
      }
    });
  };

  const getPayboxUrlHandler = async() => {
    const payboxPrimaryPage = await fetch("https://tpeweb.paybox.com/load.html")
    const classicOrResponsive = isDesktop ? environment.payboxUrl.classic : environment.payboxUrl.responsive
    const chosenPayboxUrl = payboxPrimaryPage.status === 200 ? classicOrResponsive.primary : classicOrResponsive.secondary
    setPayboxUrl(chosenPayboxUrl)
  }

  useEffect(() => {
    const newBillingForm = {
      subscription: false,
      lang: getCurrentLanguage(),
      email: me.email,
      userID: me.id,
      duration: "BOOST",
      cardType: cardType,
    };

    getPaymentParamsHandler(newBillingForm)
  }, [cardType]);

  useEffect(() => {
    if (paymentMethod == "amex") {
      setCardType("AMEX")
    }
    if (paymentMethod == "creditCard") {
      setCardType("CB")
    }
  }, [paymentMethod])

  return (
    <Boost
      isOpen={isOpen}
      onClose={handleClose}
      style={{
        backgroundImage: "url('/assets/icons/user-profile/boost-background.svg')",
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        width: isDesktop ? '465px' : '90%',
        height: 'fit-content',
        border: `1px solid ${colors.lightGrey10}`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 16,
        zIndex: 1000,
      }}
    >
      <BoostModalContainer>
        <BoostIcon src="/assets/icons/user-profile/boost-white.svg" width={42} height={42} />
        <Text bold fontSize="16px">
          {locale('boost.modal.title')}
        </Text>
        <Text fontSize="12px" center style={{ color: '#99F6E0' }}>
          {locale('boost.modal.description')}
        </Text>
        <BoostProfilePicturesWrapper>
          <img src={me?.profilePicture?.path} width={88} height={118} />
          <img src="/assets/icons/user-profile/boost-user-2.svg" width={88} height={118} />
          <img src="/assets/icons/user-profile/boost-user-3.svg" width={88} height={118} />
          <img src="/assets/icons/user-profile/boost-user-4.svg" width={88} height={118} />
          <img src="/assets/icons/user-profile/boost-user-5.svg" width={88} height={118} />
          <img src="/assets/icons/user-profile/boost-user-6.svg" width={88} height={118} />
        </BoostProfilePicturesWrapper>
        <Flex
          gap="8px"
          justify="center"
          style={{ marginTop: 20, flexWrap: "wrap" }}
        >
          <RadioBox
            size="small"
            value={paymentMethod === "creditCard"}
            onChange={() => setPaymentMethod("creditCard")}
            text={locale("subscription.payment_method.creditCard")}
          ></RadioBox>
          <RadioBox
            size="small"
            value={paymentMethod === "amex"}
            onChange={() => setPaymentMethod("amex")}
            text={"American Express"}
          ></RadioBox>
        </Flex>
        <div>
          <form
            method="post"
            action={`${payboxUrl}`}
            id="paymentParams"
          >
            {paymentParams.map((param) => (
              <input
                key={param.name}
                name={param.name}
                value={param.value}
                type="hidden"
              />
            ))}
          </form>
          <PrimaryButton
            isActive={true}
            size="large"
            form="paymentParams"
            value="submit"
            onClick={() => {
              // GA event
              window.gtag('event', 'boost_clicked', {
                event_category: 'Premium Modal',
                event_label: 'Boost Page',
                payment_method: paymentMethod,
              });
            }}
          >
            {locale('boost.button', { price: price })}
          </PrimaryButton>
          {/* <form method="POST" action="https://preprod-tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi">
            <input type="hidden" name="PBX_SITE" value="1999888"/>
            <input type="hidden" name="PBX_RANG" value="32"/>
            <input type="hidden" name="PBX_IDENTIFIANT" value="2"/>
            <input type="hidden" name="PBX_TOTAL" value="1000"/>
            <input type="hidden" name="PBX_DEVISE" value="978"/>
            <input type="hidden" name="PBX_CMD" value="TEST Paybox"/>
            <input type="hidden" name="PBX_PORTEUR" value="test@paybox.com"/>
            <input type="hidden" name="PBX_RETOUR" value="Mt:M;Ref:R;Auto:A;Erreur:E"/>
            <input type="hidden" name="PBX_HASH" value="SHA512"/>
            <input type="hidden" name="PBX_TIME" value="2011-02-28T11:01:50+01:00"/>
            <input type="hidden" name="PBX_TYPECARTE" value="CB"/>
            <input type="hidden" name="PBX_HMAC" value="E42F990CDFD19513D4EE040DA37BC46264EDF62C5FD84D396BDFEFEC1FFA5999B8186B40E26F881785720D74864A343E6E20903495F5F1BD3B95492C39595C0D"/>
            <input type="submit" value="Envoyer"/>
          </form>  */}
        </div>
      </BoostModalContainer>
    </Boost>
  );
}

const BoostIcon = styled.img`
  border-radius: 4px;
`;

const Boost = styled(UserModalTemplate)``;

const BoostModalContainer = styled.div`
  width: 100%;
  padding: 32px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  border-radius: 16px;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    padding: 32px 0;
  }
`;

const BoostProfilePicturesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 118px;
  gap: 13px;
`;
