import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import ModalTemplate from "../../components/miscellaneous/modal-template.component";

import ModalSlice from "../../store/slices/modal.slice";
import useLocale from "../../utils/locale/locale.hook";
import { replace } from "../../utils/locale/locale.utils";

import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { PrimaryButton } from "../../components/forms/primaryButton.component";
import RadioBox from "../../components/forms/radiobox.input";
import { FancyScrollbar } from "../../components/style/fancy-scrollbar.style";
import { Flex } from "../../components/style/flex.component";
import { Loader } from "../../components/style/loader.component";
import Text from "../../components/style/text.component";
import { environment } from "../../environments";
import {
  generateBillingParams,
  getWebOffers,
} from "../../services/subscription.service";
import colors from "../../themes/colors-v2.theme";
import fonts from "../../themes/fonts.theme";
import { useLogin } from "../../utils/auth.utils";
import { getCurrentLanguage } from "../../utils/locale/hreflang.utils";
import { mapDurationToValue } from "./premiumv2.screen";

type PremiumModalProps = {
  visible: boolean;
};

type OfferItemProps = {
  offer: Offer;
  onClick: () => void;
  activeTab: "subscription" | "oneShot";
  isDesktop: boolean;
  isSelected?: boolean;
};

type Offer = {
  price: string;
  duration: "1W" | "1M" | "3M" | "6M" | "1Y";
  mostPopular: boolean;
};

const Price = ({ offer }: { offer: Offer }) => {
  const priceEuros = offer.price.split(".")[0];
  const priceCents = "," + offer.price.split(".")[1];
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
        }}
      >
        <PriceText>{priceEuros}</PriceText>
        <PriceText>{priceCents}</PriceText>
      </div>
    </>
  );
};

export const OfferItem = ({ offer, isSelected }: OfferItemProps) => {
  const locale = useLocale();

  return (
    <>
      <Offer mostPopular={offer.mostPopular} selected={isSelected}>
        {/* {offer.mostPopular && <MPText>{locale('premium.popular')}</MPText>} */}

        {offer.mostPopular && (
          <PopularText>{locale("premium.popular")}</PopularText>
        )}
        <Flex gap="8px" alignItems="center">
          {isSelected ? (
            <img src="/assets/icons/nearby/checkbox-blue.svg" />
          ) : (
            <img src="/assets/icons/nearby/checkbox.svg" />
          )}

          <Text bold>{locale("premium.duration." + offer.duration)}</Text>
        </Flex>

        <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
          <Price offer={offer} />
        </div>
      </Offer>
    </>
  );
};

export const PremiumModal = (props: PremiumModalProps) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const { me } = useLogin();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const [paymentParams, setPaymentParams] = useState<
    Array<{ name: string; value: string }>
  >([]);

  const [offers, setOffers] = useState([]);
  const [activeTab] = useState<"subscription" | "oneShot">("oneShot");
  const [selectedOffer, setSelectedOffer] = useState<Offer["duration"]>("3M");
  const [paymentMethod, setPaymentMethod] = useState<
    "creditCard" | "amex" | "transfer" | "paypal" | "cheque"
  >("creditCard");
  const [cardType, setCardType] = useState<"CB" | "AMEX">("CB");
  const [payboxUrl, setPayboxUrl] = useState('');

  const [showCheckInstructions, setShowCheckInstructions] = useState(false);

  const getPaymentParamsHandler = (newBillingForm) => {

    generateBillingParams(newBillingForm).then((res) => {
      if (res?.success) {
        setPaymentParams(res.params);
      }
    });
  };

  useEffect(() => {
    const newBillingForm = {
      subscription: false,
      lang: getCurrentLanguage(),
      email: me.email,
      userID: me.id,
      duration: mapDurationToValue[selectedOffer],
      cardType: cardType,
    };

    getPaymentParamsHandler(newBillingForm);
  }, [selectedOffer, cardType]);

  useEffect(() => {
    getWebOffers().then((_offers) => setOffers(_offers));
  }, []);

  const closeModalHandler = () => {
    console.log("Subscription abandoned")
    window.gtag('event', 'leave_nearby_subscription', {
      event_category: 'Engagement',
      event_label: 'Premium Page',
    });
    dispatch(ModalSlice.actions.setPremium({ visible: false }));
  };

  const handleSelectOffer = (offerDuration: Offer["duration"]) => {
    setSelectedOffer(offerDuration);
  };

  const preventCloseModalHandler = (e) => {
    e.stopPropagation();
    return;
  };

  useEffect(() => {
    getWebOffers().then((_offers) => setOffers(_offers));
    getPayboxUrlHandler()
  }, []);

  const activeTabStyle = {
    background: colors.darkBlue,
    padding: 8,
    borderRadius: 8,
    paddingRight: 32,
  };

  useEffect(() => {
    if (paymentMethod == "amex") {
      setCardType("AMEX")
    }
    if (paymentMethod == "creditCard") {
      setCardType("CB")
    }
  }, [paymentMethod])

  const getPayboxUrlHandler = async() => {
    const payboxPrimaryPage = await fetch("https://tpeweb.paybox.com/load.html")
    const classicOrResponsive = isDesktop ? environment.payboxUrl.classic : environment.payboxUrl.responsive
    const chosenPayboxUrl = payboxPrimaryPage.status === 200 ? classicOrResponsive.primary : classicOrResponsive.secondary
    setPayboxUrl(chosenPayboxUrl)
  }

  const defaultTabStyle = { padding: 8, paddingRight: 32 };

  return (
    <ModalTemplate
      visible={props.visible}
      closeModal={closeModalHandler}
      zIndex={1020}
      style={{
        backgroundColor: "transparent",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        top: isDesktop ? "-5vh" : "0",
      }}
      isPremiumModal
      canCloseModalUsingOverlay
    >
      <GradientBackgroundWrapper>
        <ModalContainer>
          {!showCheckInstructions ? (
            <Flex direction="column" alignItems="center">
              <img
                alt=""
                width="215px"
                src="/assets/icons/logo/bearwwx.svg"
                onClick={preventCloseModalHandler}
                style={{ marginTop: 24, marginBottom: 4 }}
              />
              <Text bold>{locale("subscription.unlock_features")}</Text>
            </Flex>
          ) : (
            <>
              {isDesktop && (
                <Title>{locale("subscription.payment.method")}</Title>
              )}
            </>
          )}
          <CloseButtonWrapper>
            <Flex justify="space-between" style={{ marginBottom: 16 }}>
              <Cross
                onClick={() =>
                  showCheckInstructions
                    ? setShowCheckInstructions(false)
                    : closeModalHandler()
                }
              >
                <PrimaryButton
                  size="medium"
                  style={{ borderColor: "white", width: 36, height: 36 }}
                >
                  <img
                    alt="cross"
                    src="/assets/icons/cross-outlined-white.svg"
                    width={16}
                    height={16}
                  />
                </PrimaryButton>
              </Cross>
            </Flex>
          </CloseButtonWrapper>
          {!showCheckInstructions && (
            <GridContainer>
              <GridRow>
                <img
                  src="/assets/icons/premium/unlimited.svg"
                  alt="unlimited"
                />
                <Text>
                  {locale("subscription.benefits.unlimited_profiles")}
                </Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/receipt.svg" alt="receipt" />
                <Text>{locale("subscription.benefits.receipt")}</Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/visitors.svg" alt="visitors" />
                <Text>{locale("subscription.benefits.visits")}</Text>
              </GridRow>
              <GridRow>
                <img
                  src="/assets/icons/premium/ephemeral.svg"
                  alt="ephemeral"
                />
                <Text>{locale("subscription.benefits.ephemeral")}</Text>
              </GridRow>
              <GridRow>
                <img
                  src="/assets/icons/premium/filters-line.svg"
                  alt="filters"
                />
                <Text>{locale("subscription.benefits.filters")}</Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/typing.svg" alt="typing" />
                <Text>{locale("subscription.benefits.typing")}</Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/photo.svg" alt="photo" />
                <Text>{locale("subscription.benefits.photos")}</Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/album.svg" alt="album" />
                <Text>{locale("subscription.benefits.albums-share")}</Text>
              </GridRow>
              <GridRow>
                <img
                  src="/assets/icons/premium/incognito.svg"
                  alt="incognito"
                />
                <Text>{locale("subscription.benefits.incognito")}</Text>
              </GridRow>
            </GridContainer>
          )}
          {!showCheckInstructions ? (
            <div style={{ marginTop: 24, width: "100%" }}>
              {/* <Row onClick={preventCloseModalHandler}>
                <FilterButton
                  active={activeTab === 'subscription'}
                  onClick={() => {
                    setActiveTab('subscription');
                  }}
                >
                  {locale('global.settings.memberships')}
                </FilterButton>
                <FilterButton
                  active={activeTab === 'oneShot'}
                  onClick={() => {
                    setActiveTab('oneShot');
                  }}
                >
                  {locale('premium.category.one_shot')}
                </FilterButton>
              </Row> */}
              {activeTab === "oneShot" ? (
                <Flex
                  gap="8px"
                  justify="center"
                  style={{ marginTop: 20, flexWrap: "wrap" }}
                >
                  <RadioBox
                    style={
                      paymentMethod === "creditCard"
                        ? activeTabStyle
                        : defaultTabStyle
                    }
                    size="small"
                    value={paymentMethod === "creditCard"}
                    onChange={() => setPaymentMethod("creditCard")}
                    text={locale("subscription.payment_method.creditCard")}
                  ></RadioBox>
                  <RadioBox
                    style={
                      paymentMethod === "amex"
                        ? activeTabStyle
                        : defaultTabStyle
                    }
                    size="small"
                    value={paymentMethod === "amex"}
                    onChange={() => setPaymentMethod("amex")}
                    text={"American Express"}
                  ></RadioBox>
                  <RadioBox
                    style={
                      paymentMethod === "transfer"
                        ? activeTabStyle
                        : defaultTabStyle
                    }
                    size="small"
                    value={paymentMethod === "transfer"}
                    onChange={() => setPaymentMethod("transfer")}
                    text={locale("subscription.payment_method.transfer")}
                  ></RadioBox>
                  <RadioBox
                    style={
                      paymentMethod === "paypal"
                        ? activeTabStyle
                        : defaultTabStyle
                    }
                    size="small"
                    value={paymentMethod === "paypal"}
                    onChange={() => setPaymentMethod("paypal")}
                    text={locale("subscription.payment_method.paypal")}
                  ></RadioBox>
                  <RadioBox
                    style={
                      paymentMethod === "cheque"
                        ? activeTabStyle
                        : defaultTabStyle
                    }
                    size="small"
                    value={paymentMethod === "cheque"}
                    onChange={() => setPaymentMethod("cheque")}
                    text={locale("subscription.payment_method.cheque")}
                  ></RadioBox>
                </Flex>
              ) : null}
              {paymentMethod === "creditCard" || paymentMethod === "amex" ||
              activeTab === "subscription" ? (
                <div style={{ margin: "24px" }}>
                  <Offers onClick={preventCloseModalHandler}>
                    {offers.map((offer) => (
                      <div
                        key={offer.duration}
                        onClick={() => handleSelectOffer(offer.duration)}
                        style={{ cursor: "pointer" }}
                      >
                        <OfferItem
                          offer={offer}
                          onClick={closeModalHandler}
                          activeTab={activeTab}
                          isDesktop={isDesktop}
                          isSelected={offer.duration === selectedOffer}
                        />
                      </div>
                    ))}
                  </Offers>

                    <FormWrapper>
                      <form
                        method="post"
                        action={`${payboxUrl}`}
                        id="paymentParams"
                      >
                        {paymentParams.map((param) => (
                          <input
                            key={param.name}
                            name={param.name}
                            value={param.value}
                            type="hidden"
                          />
                        ))}
                      </form>

                      <PrimaryButton
                        isActive
                        style={{ width: "100%" }}
                        size="large"
                        type="submit"
                        value="Submit"
                        form="paymentParams"
                        onClick={() => {
                          // GA event
                          window.gtag('event', 'subscribe_clicked', {
                            event_category: 'Premium Page',
                            event_label: selectedOffer,
                            payment_method: paymentMethod,
                          });
                        }}
                      >
                        {locale("grid.premium.subscribe")}
                      </PrimaryButton>
                    </FormWrapper>

                </div>
              ) : (
                <CheckInstructions
                  dangerouslySetInnerHTML={{
                    __html: replace(locale("premium.check.instructions"), {
                      pseudo: me.pseudo,
                      email: me.email,
                    }),
                  }}
                  onClick={preventCloseModalHandler}
                />
              )}

              <Flex
                alignItems="center"
                justify="space-between"
                style={{ marginBottom: 16, marginTop: 16 }}
              >
                <Flex direction="column" style={{ flex: 1 }}>
                  <PaymentRow>
                    <img
                      src="/assets/icons/check-outlined-white.svg"
                      width={16}
                      height={16}
                    />
                    <div style={{ lineHeight: "16px" }}>
                      {locale("messages.shoutout.payment_1")}
                    </div>
                  </PaymentRow>
                  <PaymentRow>
                    <img
                      src="/assets/icons/check-outlined-white.svg"
                      width={16}
                      height={16}
                    />
                    <div style={{ lineHeight: "16px" }}>
                      {locale("messages.shoutout.payment_2")}
                    </div>
                  </PaymentRow>
                  <PaymentRow>
                    <img
                      src="/assets/icons/check-outlined-white.svg"
                      width={16}
                      height={16}
                    />
                    <div style={{ lineHeight: "16px" }}>
                      {locale("messages.shoutout.payment_3")}
                    </div>
                  </PaymentRow>
                </Flex>
                <PaymentIconsGrid>
                  <img
                    src="/assets/icons/messages/mastercard.svg"
                    width={32}
                    height={32}
                  />
                  <img
                    src="/assets/icons/messages/visa.svg"
                    width={32}
                    height={32}
                  />
                  <img
                    src="/assets/icons/messages/american-express.svg"
                    width={32}
                    height={32}
                  />
                  <img
                    src="/assets/icons/messages/CB.svg"
                    width={32}
                    height={32}
                  />
                </PaymentIconsGrid>
              </Flex>
            </div>
          ) : (
            <CheckInstructions
              dangerouslySetInnerHTML={{
                __html: replace(locale("premium.check.instructions"), {
                  pseudo: me.pseudo,
                  email: me.email,
                }),
              }}
              onClick={preventCloseModalHandler}
            />
          )}
        </ModalContainer>
      </GradientBackgroundWrapper>
    </ModalTemplate>
  );
};

const MobileBackButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.backgroundLightGrey};
  padding: 16px;
  box-sizing: border-box;
  gap: 17px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.dark};
  border-radius: 16px;
  max-height: 90vh;
  padding: 16px;
  overflow-y: auto;

  ${FancyScrollbar}
`;

const Title = styled.p`
  margin: 0;
  font-weight: ${fonts.bold.weight};
  font-size: 24px;
  text-align: left;
  width: 100%;
  padding: 35px 32px;
  box-sizing: border-box;
`;

const CloseButtonWrapper = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  z-index: 1000;
  top: 45px;
  right: 30px;
  cursor: pointer;
`;

const CheckInstructions = styled.div`
  margin-top: 12px;
  position: relative;
  margin-bottom: 8px;
  padding: 0px 38px 24px 38px;
  border-radius: 4px;
  white-space: pre-wrap;
  overflow-y: auto;
  font-size: 12px;
  max-height: calc(100vh - 200px);

  a {
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    max-height: 100vh;
    padding-top: 24px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #717171;
  margin-bottom: 16px;
  & > * {
    margin-bottom: 16px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
`;

const SubText = styled.div<{
  bold?: boolean;
  center?: boolean;
  brown?: boolean;
}>`
  color: ${colors.darkGrey1};
  font-size: 12px;
  line-height: 16px;
  font-family: ${(p) => (p.bold ? fonts.bold.name : fonts.regular.name)};
  ${(p) => (p.center ? "align-self: center" : "")};
  ${(p) => (p.center ? "margin-bottom: 22px" : "")};
`;

const TransparentButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  padding: 0;
`;

const Offers = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  box-sizing: border-box;
  padding: 21px 0px;

  @media (max-width: 1000px) {
    padding: 21px 12px;
  }
`;

const Offer = styled.div<{ mostPopular?: boolean; selected?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px
    ${({ selected }) => (selected ? colors.darkBlue : colors.lightGrey2)};
  box-sizing: border-box;
  border-radius: 8px;
  background: ${({ selected }) => (selected ? "#106BF280" : colors.dark)};
`;

const PriceText = styled.div`
  font-family: ${fonts.bold.name};
  font-weight: 600;
  font-size: 17px;
  line-height: 20.57px;
  letter-spacing: 0.1px;
`;

const GradientBackgroundWrapper = styled.div`
  position: relative;
  background: linear-gradient(
    to right,
    #d93427,
    #f69101,
    #dfd902,
    #14aa5f,
    #2d56c5,
    #4c2e7b
  );
  border-radius: 16px;
  padding: 1px;
`;

const Cross = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 11;
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 1000px) {
    width: fit-content;
    margin-top: 14px;
    margin-bottom: 6px;
    height: 44px;
    width: 100%;
    margin: 0;
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : "white")};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${colors.darkBlue}` : ""};
  width: 100%;
`;

const GridContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const GridRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PopularText = styled.div`
  position: absolute;
  top: -6px;
  background: ${colors.darkBlue};
  border-radius: 7px;
  padding: 0px 6px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  height: 15px;
`;

const PaymentRow = styled.div`
  gap: 4px;
  border: none;
  border-radius: 8px;
  height: 20px;
  color: ${colors.white};
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 14px;
  padding: 0 12px;
  @media (max-width: 1000px) {
    height: 100%;
  }
`;

const Label = styled.p`
  color: ${colors.lightGrey12};
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 4px;
`;

const PaymentIconsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;
